<template>
	<div class="newSceneBox">
		<Classification
			v-if="navData"
			:navData="navData"
			@navBtn="navBtn"
		></Classification>
		<div class="contBox">
			<div class="topClassification flex-center">
				<div
					class="list display pointer"
					:class="topActive == index ? 'active' : ''"
					v-for="(item, index) in secondLevel"
					:key="index"
					@click="topBtn(item, index)"
				>
					{{ item.label }}
				</div>
			</div>
			<div
				class="sceneList flex"
				v-for="(item, index) in threeLevel"
				:key="index"
			>
				<div class="sceneList_left">
					<div class="scene_left_list">
						<div class="scene_left_list_label">{{ item.label }}</div>
						<div class="sidebar">
							<div
								class="list pointer display"
								v-for="(items, indexs) in item.list"
								:key="indexs"
								:class="sceneIndex == indexs ? 'sceneListActive' : ''"
								@click="sceneBtn(item, items, indexs)"
							>
								<span class="row"> {{ items.systemClassificationName }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="sceneList_right">
					<div class="sceneImgBox">
						<div
							class="sceneImg"
							:style="{ background: `url(${item.url}) center no-repeat` }"
						>
							<span
								class="pointer"
								@click="sceneBtn(item, items, indexs)"
								:style="{
									top: items.ycoordinate + 'px',
									left: items.xcoordinate + 'px',
								}"
								v-for="(items, indexs) in item.list"
								:key="indexs"
							></span>
						</div>
					</div>
					<div class="swiperBox" v-if="item.parameter">
						<div>{{ item.parameter }}</div>
					</div>

					<div class="swiperBox" v-else>
						<div class="swiper_box">
							<swiper
								class="swiper"
								v-if="item && item != null"
								ref="swiper"
								:options="swiperOptions"
							>
								<swiper-slide
									class="swiper_goodsBox"
									v-for="(items, indexs) in item.goodsList"
									:key="indexs"
								>
									<div class="goodsBox pointer" @click="toDetails(items.id)">
										<div class="goodsImg">
											<img
												class="goods_img"
												v-if="items"
												:src="items.coverUrl"
												alt=""
											/>
										</div>
										<div class="goods_cont display column">
											<div class="goodsName astrict" v-if="items">
												{{ items.productName }}
											</div>
											<div class="goodsPrice" v-if="items">
												￥{{ items.price }}
											</div>
										</div>
									</div>
								</swiper-slide>

								<div class="swiper-button-prev" slot="button-prev">
									<img
										class="icon_left_right"
										src="../../assets/img/left.png"
										alt=""
									/>
								</div>
								<div class="swiper-button-next" slot="button-next">
									<img
										class="icon_left_right"
										src="../../assets/img/right.png"
										alt=""
									/>
								</div>
							</swiper>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Classification from '@/components/classification.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
	components: {
		Classification,
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			bgImg: require('../../assets/img/scene/bg1.png'),
			bgImg2: require('../../assets/img/scene/bg2.png'),
			topActive: 0,
			navData: [],
			secondLevel: [],
			sceneIndex: 0,
			threeLevel: [],
			sceneList: [],
			navItem: '',
			params: {
				category: 2,
			},
			swiperOptions: {
				slidesPerView: 4,
				speed: 1000, //匀速时间
				observer: true,
				observeParents: true,
				// autoplay: {
				//     delay: 0,
				//     stopOnLastSlide: false,
				//     disableOnInteraction: false,
				// },
				spaceBetween: 22,
				direction: 'horizontal',
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				on: {
					resize: () => {
						this.$refs.swiper.$swiper.changeDirection(
							window.innerWidth <= 960 ? 'vertical' : 'horizontal'
						)
					},
				},
			},
			scenarioData: [],
		}
	},

	mounted() {
		this.init()
	},
	beforeDestroy() {
		//离开页面清除数据
		this.navItem = ''
		sessionStorage.removeItem('navItem')
	},
	methods: {
		// 获取数据
		init() {
			this.api.getTreeSelect(this.params).then((res) => {
				res.data.forEach((item) => {
					item.name = item.label
				})
				this.navData = res.data
				let navItemData = JSON.parse(sessionStorage.getItem('navItem'))

				if (navItemData != null) {
					this.classificationIndex = 0
					this.sceneIndex = 0
					this.secondLevel = navItemData.children
					this.threeLevel = navItemData.children[0].children
				} else {
					this.secondLevel = this.navData[0].children
					this.threeLevel = this.navData[0].children[0].children
				}

				this.getThreeLevel()
			})
		},

		getThreeLevel() {
			let arr = []
			if (this.threeLevel) {
				this.threeLevel.forEach((items) => {
					this.api
						.findSystemClassificationScenes({ id: items.id })
						.then((datas) => {
							if (datas.data) {
								this.$set(items, 'list', datas.data)

								if (datas.data[0].category == 1) {
									arr.push(items.list[0].productListVO)
									this.$set(items, 'goodsList', arr)
								} else if (datas.data[0].category == 2) {
									let params = {
										id: Number(datas.data[0].parameter),
										token: this.$store.state.userData.token,
									}
									this.api.findClassificationProduct(params).then((res) => {
										this.$set(items, 'goodsList', res.data)
									})
								} else {
									this.$set(items, 'parameter', datas.data.parameter)
								}
							}
						})
				})
			}
		},

		//点击tab分类
		navBtn(item) {
			sessionStorage.setItem('navItem', JSON.stringify(item))
			this.topActive = 0
			this.sceneIndex = 0
			this.secondLevel = item.children || []
			this.secondLevelBgImg = item.children[0].url
			this.threeLevel = item.children[0].children || []
			this.getThreeLevel()
		},

		topBtn(item, index) {
			this.topActive = index
			this.secondLevelBgImg = item.url
			this.threeLevel = item.children
			this.getThreeLevel()
		},
		//点击左侧分类
		sceneBtn(item, items, index) {
			if (items.category == 1) {
				item.goodsList = []
				item.goodsList.push(items.productListVO)
			} else if (items.category == 2) {
				let params = {
					id: Number(items.parameter),
					token: this.$store.state.userData.token,
				}
				this.api.findClassificationProduct(params).then((res) => {
					item.goodsList = res.data
				})
			}
			this.sceneIndex = index
		},
		toDetails(id) {
			this.$router.push({
				path: '/mall/goodsDetails',
				query: {
					id: id,
				},
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.newSceneBox {
	.contBox {
		width: 1200px;
		margin: 0 auto;
		.topClassification {
			width: 1200px;
			height: 88px;
			background: #ffffff;
			border-radius: 3px;
			margin-top: 20px;
			padding: 0px 20px;
			box-sizing: border-box;
			.list {
				width: 116px;
				height: 48px;
				font-size: 16px;
				color: #333333;
			}
			.active {
				background: #414551;
				border-radius: 24px;
				color: white !important;
			}
		}

		.sceneList {
			width: 100%;
			margin: 20px 0;
			box-sizing: border-box;
			.sceneList_left {
				width: 160px;
				height: 760px;
				padding: 40px 20px;
				box-sizing: border-box;
				background: white;
				.scene_left_list {
					.scene_left_list_label {
						font-size: 24px;
						line-height: 24px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						margin-bottom: 23px;
					}
					.sidebar {
						width: 100%;
						height: 620px;
						overflow: hidden;
						overflow-y: scroll;
					}
					.list {
						width: 120px;
						height: 34px;
						background: #f4f5f5;
						border-radius: 17px;
						margin-bottom: 20px;
						font-size: 14px;
						padding: 0px 10px;
						span {
							width: 100px;
							text-align: center;
						}
					}
					.sceneListActive {
						background: #414551 !important;
						color: #fff !important;
					}
				}
			}
			.sceneList_right {
				width: 980px;
				height: 780px;
				margin-left: 20px;
				box-sizing: border-box;
				.sceneImgBox {
				}
				.sceneImg {
					width: 1020px;
					height: 456px;
					position: relative;
					span {
						width: 80px;
						height: 26px;
						position: absolute;
						margin-top: -10px;
						margin-left: -5px;
					}
				}
				.swiperBox {
					width: 1020px;
					height: 283px;
					background: #ffffff;
					padding-top: 20px;
					margin-top: 20px;
					box-sizing: border-box;
				}
			}
		}
	}
}

.swiper_box {
	.swiper {
		.swiper_goodsBox :hover {
			.goodsName {
				color: #e1251b !important;
			}
		}
		.goodsBox {
			.goodsImg {
				display: flex;
				align-items: center;
				justify-content: center;
				.goods_img {
					width: 159px;
					height: 159px;
				}
			}
			.goods_cont {
				.goodsName {
					width: 170px;
					height: 33px;
					font-size: 12px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 18px;
					margin-top: 22px;
				}
				.goodsPrice {
					width: 170px;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #e1251b;
					text-align: left;
					line-height: 18px;
					margin-top: 16px;
				}
			}
		}
	}
	.swiper-button-prev {
		left: 0px;
		width: 24px;
		height: 38px;
		background: rgba(51, 51, 51, 0.4);
		border-radius: 0px 4px 4px 0px;
	}
	.swiper-button-next {
		right: 0px;
		width: 24px;
		height: 38px;
		background: rgba(51, 51, 51, 0.4);
		border-radius: 4px 0px 0px 4px;
	}
	.swiper-button-prev:after,
	.swiper-button-next:after {
		content: none;
	}
	.icon_left_right {
		width: 11px;
		height: 21px;
	}
}
</style>